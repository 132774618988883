import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "modal",
      "style": {
        "position": "relative"
      }
    }}>{`Modal`}</h1>
    <p>{`O componente é utilizado para apresentar conteúdos em um nível acima do restante da interface. É utilizado quando há a necessidade de visualizar, criar ou editar um conteúdo, ou para mensagens de confirmação. Nenhuma outra informação da página que gerou o modal pode ser acessada enquanto o mesmo estiver ativo.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Utilize modais com cautela, pois eles interrompem o fluxo de uso do produto.`}</li>
      <li parentName="ul">{`O título deve refletir a ação ou botão que foi clicado pelo usuário.`}</li>
      <li parentName="ul">{`Podem ser fechados tanto pelo ícone de fechar, como pressionando ESC ou clicando fora da área do modal, se nenhuma ação tiver sido executada no contexto do modal.`}</li>
      <li parentName="ul">{`Mantenha o foco anterior do usuário após fechar o modal.`}</li>
    </ul>
    <h2 {...{
      "id": "tipos-de-modal",
      "style": {
        "position": "relative"
      }
    }}>{`Tipos de Modal`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={160}>Informação</th>
      <td>
        Usados para exibir informações que não requerem input do usuário. Normalmente exibem somente textos que não
        precisam ser exibidos diretamente na tela.
      </td>
    </tr>
    <tr>
      <th>Confirmação</th>
      <td>
        Usados para validar decisões ou para obter uma segunda confirmação do usuário. Normalmente requerem respostas do
        usuário.
      </td>
    </tr>
    <tr>
      <th>Entrada de dados</th>
      <td>São utilizados para receber dados e incluem áreas de input que o usuário pode interagir.</td>
    </tr>
  </tbody>
    </table>
    <h2 {...{
      "id": "exemplos",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplos`}</h2>
    <h3 {...{
      "id": "informacao",
      "style": {
        "position": "relative"
      }
    }}>{`Informação`}</h3>
    <Demo src='pages/components/modal/InformationModal' mdxType="Demo" />
    <h3 {...{
      "id": "confirmacao",
      "style": {
        "position": "relative"
      }
    }}>{`Confirmação`}</h3>
    <Demo src='pages/components/modal/ConfirmationModal' mdxType="Demo" />
    <h3 {...{
      "id": "acao-perigosa",
      "style": {
        "position": "relative"
      }
    }}>{`Ação perigosa`}</h3>
    <Demo src='pages/components/modal/DangerModal' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      